import { IS_SERVER } from './checkRenderEnv';

export const getTitleWithoutTags = (title: string) => {
  if (IS_SERVER) {
    return title;
  }
  const htmlString = title;
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  const text = tempDiv.textContent || tempDiv.innerText;
  return text;
};
