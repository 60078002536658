import { isMobileDevice } from "src/components/WidgetMaker/WidgetDnD/isMobileDevice";
import { EditableText } from "../RichTextImage/component/TextEditor";
import { getDescriptionStyle, getTitleStyles } from "./MultiColumnHelper";
import { getButtonVariantsData } from "../Carousel/utils";
import CustomizedButton from "src/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button";
import { BUTTON_SIZE_TYPE } from "src/components/WidgetMaker/utils/buttonConstant";
import { btnActionHandler } from "../../ModernTheme/utils/btnUtils";
import classNames from "classnames";
import { getCurrentClass, getShapeStyles } from "../CardFrameCollection/utils";
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import { useWidgetDndContextData } from "src/context/WidgetDndContext";
import styles from './multiColumn.module.scss'
import { getTitleWithoutTags } from "src/utils/getStringFromHtmlTag";

const REMOVE_GLOBAL_STYLE = ['circle', 'arch', 'blob'];

const MultiColumnComponent = (props) => {
  const { listPresentationConfig, cardFrameConfig, oneGridCellGeometry, columnData, index } = props;
  const {
    widgetContextState,
    widgetContextHelpers: { handleWidgetPropChange },
  } = useWidgetDndContextData();

  const { widgets, activeWidgetId } = widgetContextState;

  const activeWidget = widgets?.[activeWidgetId];
  const activeWidgetProps = activeWidget?.props;

  const isMobile = isMobileDevice();
  const { iconSizeDesktop, iconSizeMobile, iconPosition,
    iconAlignment, showTitle, showDescription, buttonConfig, textAlignment,
    imageShape, isShow } = cardFrameConfig;
  const { appearance, borderColor, backgroundColor } = listPresentationConfig;
  const isSelected = activeWidgetProps?.subWidgetIndex === index && listPresentationConfig?.type === activeWidgetProps?.listPresentation?.type;

  function renderIcon(columnData) {
    const { imageDetail: { url } } = columnData
    return (
      <div style={{
        justifyContent: textAlignment,
        minWidth: isMobile ? `${iconSizeMobile}px` : `${iconSizeDesktop}px`,
        width: iconPosition === 'left' ? isMobile ? `${iconSizeMobile}px` : `${iconSizeDesktop}px` : '100%',
      }} className={`tw-flex tw-w-[100%]`}>
        <img
          src={url || '/assets/icons/multi-column-icon.svg'}
          className={styles['custom-border-radius']}
          style={{
            height: isMobile ? `${iconSizeMobile}px` : `${iconSizeDesktop}px`,
            width: isMobile ? `${iconSizeMobile}px` : `${iconSizeDesktop}px`,
          }}
        />
      </div>
    )
  }

  function renderImage(data, index) {
    const { imageDetail: { url } } = data;
    const shapeStyleCalculated = getShapeStyles(imageShape, oneGridCellGeometry?.width);
    const shapeStyle = shapeStyleCalculated[0];

    return (
      <div
        className={classNames(
          `tw-relative tw-flex-shrink-0 tw-overflow-hidden ${!REMOVE_GLOBAL_STYLE?.includes(imageShape) ? 'imgWrapperGlobalStyle' : ''}`,
          shapeStyle.className
        )}
        style={{
          width: `${oneGridCellGeometry?.width - (appearance === 'card' ? 40 : 0)}px`,
          height: `${oneGridCellGeometry?.height - (appearance === 'card' ? 40 : 0)}px`,
          ...(shapeStyle.style || {}),
          justifyContent: textAlignment,
        }}>
        <img
          src={url || '/assets/images/richTextWithImage.png'}
          className={classNames(
            'tw-absolute tw-h-[100%] tw-w-[100%] tw-object-cover tw-transition-all tw-duration-500',
            contentAnimationStyle[getCurrentClass(cardFrameConfig?.hoverType)],
            shapeStyle.className
          )}
        />
      </div>
    )
  }

  function renderIconAndImage(columnData, index) {
    if (listPresentationConfig?.type === 'icon') {
      return isShow && renderIcon(columnData)
    }
    return isShow && renderImage(columnData, index)
  }

  function onNavigate(e, index: number) {
    if (activeWidgetId) {
      handleWidgetPropChange(
        {
          subWidget: '',
          subWidgetIndex: -1,
        },
        activeWidgetId
      );
      setTimeout(() => {
        handleWidgetPropChange(
          {
            subWidget: 'multiColumn',
            subWidgetIndex: index,
            cardFrame: activeWidgetProps?.cardFrame
          },
          activeWidgetId
        );
      }, 0)
    }
  }

  function renderCustomHtml(content) {
    return (
      <EditableText
        content={content}
        className="tw-leading-[22.4px]"
      ></EditableText>
    )
  }

  function renderTitleAndDescription(columnData) {
    const { title, description } = columnData;
    const titleStyle = getTitleStyles(cardFrameConfig, isMobile)
    const descriptionStyle = getDescriptionStyle(cardFrameConfig, isMobile);
    return (
      <div className={`tw-flex tw-flex-col tw-gap-[8px]`}>
        {showTitle && <div style={{
          color: cardFrameConfig?.titleColor || '',
          ...titleStyle?.style,
          textAlign: textAlignment
        }}>
          {renderCustomHtml(title)}
        </div>}
        {showDescription && <div style={{
          color: cardFrameConfig?.descriptionColor,
          ...descriptionStyle?.style,
          textAlign: textAlignment,
          marginBottom: !buttonConfig?.[0]?.isShow ? '16px' : ''
        }}>
          {renderCustomHtml(description)}
        </div>}
      </div>
    )
  }

  function renderButton(columnData) {
    const actionObj = {
      type: columnData?.redirectionType || '',
      handler: columnData?.redirectUrl || '',
      newTab: columnData?.isOpenInNewTab || ''
    }
    return (
      <div className={`tw-flex ${textAlignment === 'center' ? 'tw-justify-center' : ''} tw-mb-[16px]`}>
        {buttonConfig?.map((buttonData, btnIndex) => {
          const buttonVariant = getButtonVariantsData(buttonData, false);
          const selectedButtonType = buttonVariant[buttonData.type];
          return buttonData.isShow ? (
            <CustomizedButton
              key={btnIndex}
              size={BUTTON_SIZE_TYPE.LARGE}
              btnType={buttonData.type}
              buttonData={buttonData}
              selectedButtonData={selectedButtonType}
              variant={selectedButtonType.variant}
              onClick={() => btnActionHandler(actionObj, () => { })}
              className={`tw-text-[14px] !tw-py-[12px] !tw-min-w-[148px]`}
            >
              {buttonData?.text}
            </CustomizedButton>
          ) : null;
        })}
      </div>
    )
  }

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        onNavigate(e, index);
        return;
      }}
      className={`tw-flex tw-w-[100%]`}>
      <div
        style={{
          ...(appearance === 'card' ?
            {
              border: isSelected ? '1px solid #2B74D8' : `1px solid ${borderColor || 'black'}`,
              background: backgroundColor,
              padding: isMobile ? '20px 14px' : '24px 20px'
            }
            : {}),
          ...(appearance !== 'card' && isSelected ? { border: '1px solid #2B74D8' } : {}),
          width: oneGridCellGeometry?.width
        }}
        className={`tw-flex tw-relative tw-gap-[20px] tw-mx-auto tw-cursor-pointer
          ${iconPosition === 'left' ? `tw-flex-row ${iconAlignment === 'center' ? 'tw-items-center' : 'tw-justify-start'}` : ' tw-flex-col '}
          ${appearance === 'card' ? 'imgWrapperGlobalStyle' : ''}
          `
        }
      >
        {isSelected && (
          <div className="tw-absolute tw-top-[-27.5px] tw-left-0 tw-w-[108px] tw-bg-[#2F80ED] tw-px-[12px] tw-py-[4px] tw-text-center">
            <span className="tw-text-[14px] tw-font-normal tw-text-[#fff]">{`Column ${activeWidgetProps?.subWidgetIndex + 1}`}</span>
          </div>
        )}
        {renderIconAndImage(columnData, index)}
        {((showTitle && getTitleWithoutTags(columnData?.title)) || (showDescription && getTitleWithoutTags(columnData?.description)) || buttonConfig?.[0]?.isShow) &&
          <div className="tw-flex tw-flex-col tw-gap-[20px]">
            {((showTitle && getTitleWithoutTags(columnData?.title)) || (showDescription && getTitleWithoutTags(columnData?.description))) && renderTitleAndDescription(columnData)}
            {buttonConfig?.[0]?.isShow && renderButton(columnData)}
          </div>}
      </div>
    </div>
  )
}

export default MultiColumnComponent;