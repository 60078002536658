import React from "react";

export const getTitleStyles = (config, isMobile) => {
  const style: React.CSSProperties = {};
  const currFontType = isMobile ? config?.titleSizeMobile : config?.titleSizeDesktop;
  switch (currFontType) {
    case 'extraSmall':
      style.fontSize = '12px';
      style.lineHeight = '1';
      break;
    case 'small':
      style.fontSize = '14px';
      style.lineHeight = '1';
      break;
    case 'medium':
      style.fontSize = '16px';
      style.lineHeight = '1.2';
      break;
    case 'large':
      style.fontSize = '18px';
      style.lineHeight = '1.2';
      break;
    case 'extraLarge':
      style.fontSize = '20px';
      style.lineHeight = '1.2';
      break;
    default:
      style.fontSize = '16px';
      style.lineHeight = '1.1';
      break;
  }

  switch (config?.titleThicness) {
    case 'thin':
      style.fontWeight = '300';
      break;
    case 'regular':
      style.fontWeight = '400';
      break;
    case 'medium':
      style.fontWeight = '500';
      break;
    case 'bold':
      style.fontWeight = '700';
      break;
    default:
      style.fontWeight = '400';
      break;
  }

  switch (config?.titleTextCase) {
    case 'none':
      style.textTransform = 'none'
      break;
    case 'capitalize':
      style.textTransform = 'capitalize'
      break;
    case 'uppercase':
      style.textTransform = 'uppercase';
      break;
  }

  return { style };
};

export const getDescriptionStyle = (config, isMobile) => {
    const style: React.CSSProperties = {};
    const currFontType = isMobile ? config?.descriptionSizeMobile : config?.descriptionSizeDesktop;
    switch (currFontType) {
      case 'extraSmall':
        style.fontSize = '12px';
        style.lineHeight = '1';
        break;
      case 'small':
        style.fontSize = '14px';
        style.lineHeight = '1';
        break;
      case 'medium':
        style.fontSize = '16px';
        style.lineHeight = '1.2';
        break;
      case 'large':
        style.fontSize = '18px';
        style.lineHeight = '1.2';
        break;
      case 'extraLarge':
        style.fontSize = '20px';
        style.lineHeight = '1.2';
        break;
      default:
        style.fontSize = '16px';
        style.lineHeight = '1.1';
        break;
    }
  
    switch (config?.descriptionThicness) {
      case 'thin':
        style.fontWeight = '300';
        break;
      case 'regular':
        style.fontWeight = '400';
        break;
      case 'medium':
        style.fontWeight = '500';
        break;
      case 'bold':
        style.fontWeight = '700';
        break;
      default:
        style.fontWeight = '400';
        break;
    }
  
    switch (config?.descriptionTextCase) {
      case 'none':
        style.textTransform = 'none'
        break;
      case 'capitalize':
        style.textTransform = 'capitalize'
        break;
      case 'uppercase':
        style.textTransform = 'uppercase';
        break;
    }
  
    return { style };
}

export const getDateStyle = (config, isMobile) => {
  const style: React.CSSProperties = {};
  const currFontType = config?.dateSize;
  switch (currFontType) {
    case 'extraSmall':
      style.fontSize = '12px';
      style.lineHeight = '1';
      break;
    case 'small':
      style.fontSize = '14px';
      style.lineHeight = '1';
      break;
    case 'medium':
      style.fontSize = '16px';
      style.lineHeight = '1.2';
      break;
    case 'large':
      style.fontSize = '18px';
      style.lineHeight = '1.2';
      break;
    case 'extraLarge':
      style.fontSize = '20px';
      style.lineHeight = '1.2';
      break;
    default:
      style.fontSize = '16px';
      style.lineHeight = '1.1';
      break;
  }

  switch (config?.dateThicness) {
    case 'thin':
      style.fontWeight = '300';
      break;
    case 'regular':
      style.fontWeight = '400';
      break;
    case 'medium':
      style.fontWeight = '500';
      break;
    case 'bold':
      style.fontWeight = '700';
      break;
    default:
      style.fontWeight = '400';
      break;
  }

  switch (config?.dateTextCase) {
    case 'none':
      style.textTransform = 'none'
      break;
    case 'capitalize':
      style.textTransform = 'capitalize'
      break;
    case 'uppercase':
      style.textTransform = 'uppercase';
      break;
  }
  return { style };
}